import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// product
const firebaseConfig = {
    apiKey: 'AIzaSyDz902T0-oQIMrAiq6fpjWlk0Csow2sQGg',
    authDomain: 'testdb-4b5f2.firebaseapp.com',
    projectId: 'testdb-4b5f2',
    storageBucket: 'testdb-4b5f2.appspot.com',
    messagingSenderId: '772465747560',
    appId: '1:772465747560:web:ab28016a863a6ef5927ddd',
    measurementId: 'G-KL623DK8FY',
  };

  const app = initializeApp(firebaseConfig);
  const firestore = getFirestore(app);
  const imgDB = getStorage(app);
  const txtDB = getStorage(app);
  // dev
  // const firebaseConfig = {
  //     apiKey: "",
  //     authDomain: "",
  //     projectId: "",
  //     storageBucket: "",
  //     messagingSenderId: "",
  //     appId: "",
  //     measurementId: ""
  //   };
  // const app = initializeApp(firebaseConfig);
  // const firestore = getFirestore(app);
  // connectFirestoreEmulator(firestore, 'localhost', 8080);

  export {firestore, imgDB, txtDB}