import { Center, Image, Text, Button } from '@chakra-ui/react';
import dislandLogo from '../../../img/Disland_LogoDisland_Logo.png';
import line from '../../../img/Scratch line.png';
import bottom from '../../../img/Group 81imgLeft_bottom.png';
import button from '../../../img/Group 79bg_Button.png';

function Left() {
  // &:active {
  //   transform: translate(0.05em, 0.05em);
  //   box-shadow: 0.05em 0.05em rgb(53, 57, 53);
  return (
    <Center
      position={'relative'}
      display={'flex'}
      flexDirection={'column'}
      w={'369px'}
      h={'536.5px'}
    >
      <Image
        position={'absolute'}
        top={'-70px'}
        zIndex={'1'}
        w={'369px'}
        h={'205px'}
        src={dislandLogo}
      />
      <Center w={'350px'} h={'241px'} bgColor={'#186585'}>
        <Image mt={'25px'} w={'1px'} h={'200px'} src={line} />
        <Text
          mt={'60px'}
          mx={'10px'}
          w={'304px'}
          h={'130px'}
          fontSize={'18px'}
          fontWeight={'600'}
          fontFamily={'Open Sans'}
          textAlign={'center'}
          color={'#ffff'}
        >
          D’islands revolutionizes blockchain gaming with a dynamic metaverse
          filled with endless FOCG activities where entertainment and
          sustainability intersect.
        </Text>
        <Image mt={'25px'} w={'1px'} h={'200px'} src={line} />
      </Center>

      <Center w={'350px'} h={'146.5px'} bgImage={bottom}>
        <Button
          _hover={{
            transform: 'translateY(-1px)',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.15)',
          }}
          _active={{
            transform: 'translateY(1px)',
            boxShadow: 'inset 0 2px 4px rgba(0, 0, 0, 0.2)',
            backgroundColor: 'rgba(0, 0, 0, 0.1)',
          }}
          mb={'50px'}
          colorScheme="black"
          borderRadius={'none'}
          color={'white'}
          fontWeight={'700'}
          fontSize={'20px'}
          lineHeight={'30px'}
          textAlign={'center'}
          w={'326px'}
          h={'64px'}
          bgImage={button}
        >
          PLAY NOW
        </Button>
      </Center>
    </Center>
  );
}

export default Left;
