import React, { useRef, useEffect } from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import theme from './component/PC/theme';
import './App.css';
import { isMobile } from 'react-device-detect';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import LayoutPC from './component/PC/LayoutPC';
import HeroBannerPC from './component/PC/HeroBanner/HeroBanner';
import WorkPC from './component/PC/Work/Work';
import GameListPC from './component/PC/GameList/GameList';
import DynamicWorldPC from './component/PC/DynamicMetaverse/DynamicWorld';
import TicketPC from './component/PC/Ticket';
import RoadmapPC from './component/PC/ROADMAP/Roadmap';
import PartnerPC from './component/PC/Partner';
import AboutPC from './component/PC/About/About';
import BlogPostPC from './component/PC/Blog';
import CardKnightWebGL from './component/PC/CardKnight';


import LayoutMB from './component/Mobile/LayoutMB';
import HeroBannerMB from './component/Mobile/HerroBanner/HeroBanner';
import WorkMB from './component/Mobile/Work/Work';
import GameListMB from './component/Mobile/GameList/GameList';
import DynamicWorldMB from './component/Mobile/Dynamic/DynamicWorld';
import TicketMB from './component/Mobile/Ticket';
import RoadmapMB from './component/Mobile/Roadmap/Roadmap';
import PartnerMB from './component/Mobile/Partner';
import AboutMB from './component/Mobile/About/About';
import BlogPostMB from './component/Mobile/Blog';
import CardKnightWebMB from './component/Mobile/CardKnight';


function App() {
  const RefGameListPC = useRef(null);
  const RefDynamicWorldPC = useRef(null);
  const RefTicketPC = useRef(null);
  
  const RefGameListMB = useRef(null);
  const RefDynamicWorldMB = useRef(null);
  const RefTicketMB = useRef(null);



  if (isMobile) {
    return (
      <ChakraProvider theme={theme}>
        <Router>
        <Routes>
          <Route path="/" element={
            <LayoutMB>
              <HeroBannerMB />
              <WorkMB
                RefGameList={RefGameListMB}
                RefDynamicWorld={RefDynamicWorldMB}
                RefTicket={RefTicketMB}
                onButtonClick={(ref) => {
                  if (ref && ref.current) {
                    const yOffset = -100; // Offset from the top
                    const y = ref.current.getBoundingClientRect().top + window.pageYOffset + yOffset;
                    window.scrollTo({ top: y, behavior: 'smooth' });
                  }
                }}
              />
              <GameListMB RefGameList={RefGameListMB} />
              <DynamicWorldMB RefDynamicWorld={RefDynamicWorldMB} />
              <TicketMB RefTicket={RefTicketMB} />
              <RoadmapMB />
              <PartnerMB />
            </LayoutMB>}
          />
          <Route path="/about" element={<AboutMB />} />
          <Route path="/post/:slug" element={<BlogPostMB />}/>
          <Route path="/cardknight" element={<CardKnightWebMB />}/>
        </Routes>
      </Router>
      </ChakraProvider>
    );
  }
  return (
    <ChakraProvider theme={theme}>
      <Router>
        <Routes>
          <Route path="/" element={
            <LayoutPC >
              <HeroBannerPC />
              <WorkPC
                RefGameList={RefGameListPC}
                RefDynamicWorld={RefDynamicWorldPC}
                RefTicket={RefTicketPC}
                onButtonClick={(ref) => {
                  if (ref && ref.current) {
                    const yOffset = -100; // Offset from the top
                    const y = ref.current.getBoundingClientRect().top + window.pageYOffset + yOffset;
                    window.scrollTo({ top: y, behavior: 'smooth' });
                  }
                }}
              />
              <GameListPC RefGameList={RefGameListPC} />
              <DynamicWorldPC RefDynamicWorld={RefDynamicWorldPC} />
              <TicketPC RefTicket={RefTicketPC} />
              <RoadmapPC/>
              <PartnerPC />
            </LayoutPC>}
          />
          <Route path="/about" element={
              <AboutPC/>
            } />
          <Route path="/cardknight" element={
            <CardKnightWebGL/>
          } />
          <Route path="/post/:slug" element={<BlogPostPC />}/>
        </Routes>
      </Router>
    </ChakraProvider>
  );
}

export default App;
