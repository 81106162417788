import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Image, Heading, Text, Flex, Avatar, Divider, VStack } from '@chakra-ui/react';
import { firestore } from '../backend/FirebaseConfig';
import { doc, getDoc, collection, query, where, getDocs } from 'firebase/firestore';

const BlogPost = () => {
  const { slug } = useParams();
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const postsRef = collection(firestore, 'post');
        const q = query(postsRef, where('slug', '==', slug));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const postData = querySnapshot.docs[0].data();
          setPost(postData);
        } else {
          console.error('No such document!');
        }
      } catch (error) {
        console.error('Error fetching post:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchPost();
  }, [slug]);

  if (loading) {
    return <Box>Loading...</Box>;
  }

  if (!post) {
    return <Box>Post not found</Box>;
  }

  return (
    <Box maxW="container.md" mx="auto" p={5} boxShadow="lg" borderRadius="md" bg="white">
      <VStack spacing={4} align="stretch">
        <Flex align="center">
          <Image src={post.image} alt={post.title} borderRadius="md" h={'100px'} w={'100px'} mr={4}/>
          <Heading as="h1" size="xl">{post.title}</Heading>
        </Flex>
        <Flex align="center" justify="space-between" width="100%">
          <Flex align="center">
            <Avatar name={post.author} src={post.authorImage} mr={3} />
            <Box>
              <Text fontWeight="bold">{post.author}</Text>
              <Text fontSize="sm" color="gray.500">{post.publishDate}</Text>
            </Box>
          </Flex>
        </Flex>
        <Divider />
        <Text fontSize="lg" lineHeight="tall">{post.content}</Text>
      </VStack>
    </Box>
  );
};

export default BlogPost;
