import { Flex, Image, Button, Box, Text } from '@chakra-ui/react';
import { Link, useNavigate } from 'react-router-dom';
import FTT1 from '../../../img/FTT1.png';
import FTT4 from '../../../img/FTT4.png';
import FTT3 from '../../../img/FTT3.png';
import logo2 from '../logo3.svg';
import cm1 from '../../../img/cm1.png';
import cm2 from '../../../img/cm02.png';
import cm3 from '../../../img/cm03.png';
import cm4 from '../../../img/cm04.png';
import line from '../../../img/lineFT.png'
import icon from '../../../img/icon.png';
import React from 'react';
import '../../icon.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquareXTwitter, faDiscord, faGithub, faMediumM } from '@fortawesome/free-brands-svg-icons';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { useEffect, useState } from 'react';
function Footer() {
  const handleClick = () => {
    window.open("https://docs.dislands.com/", "_blank", "noopener noreferrer");
  };

  const handleClickX = () => {
    window.open("https://x.com/0xDislands", "_blank", "noopener noreferrer");
  };
  const handleClickDiscord = () => {
    window.open("https://discord.gg/AZ7HEYy5nX", "_blank", "noopener noreferrer");
  };
  const handleClickMedium = () => {
    window.open("https://medium.com/@dislands", "_blank", "noopener noreferrer");
  };
  const handleClickGithub = () => {
    window.open("https://github.com/0xDislands/", "_blank", "noopener noreferrer");
  };

  const navigate = useNavigate();

  const handleButtonClick = (refName) => {
    navigate('/'); // Điều hướng về trang chủ
    navigate(`/?scrollTo=${refName}`);
  }

  const [isScrolled, setIsScrolled] = useState(false); // Track scroll state

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0); // Update state on scroll
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll); // Cleanup
  }, []);

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' }); // Smooth scroll to top
  };

  return (
    <Box w="100%" bgColor={'#FFF6E4'} position="relative">

      <Flex
        w="100%"
        h="533px"
        zIndex={1}
        bgImage={FTT4}
        bgSize="100% 100%"
        bgRepeat="no-repeat"
        position="relative"
        direction="column"
        align="center"
        justify="center"
      >
        <Image
          position="absolute"
          src={FTT3}
          blendMode="multiply"
          w="100%"
          h="533px"
        />
        <Image src={logo2} zIndex={3} w="60%" />
        <Box w="100%" mt="30px">
          <Image src={line} position={'relative'} />
          <Button
            w="100%"
            h="40px"
            bg="unset"
            variant="link"
            onClick={() => handleButtonClick('RefWork')}
          >
            <Flex justify="center" align="center" gap={'10px'}>
              <Image src={icon} />
              <Text fontFamily="Rowdies" textColor="#FFF6E4" fontSize={'14px'}>
                FEATURES
              </Text>
            </Flex>
          </Button>
          <Image src={line} position={'relative'} />
          <Button
            w="100%"
            h="40px"
            bg="unset"
            variant="link"
            onClick={() => handleButtonClick('RefROADMAP')}
          >
            <Flex justify="center" align="center" gap={'10px'}>
              <Image src={icon} />
              <Text fontFamily="Rowdies" textColor="#FFF6E4" fontSize={'14px'}>
                ROADMAP
              </Text>
            </Flex>
          </Button>
          <Image src={line} position={'relative'} />
          <Button
            w="100%"
            h="40px"
            bg="unset"
            variant="link"
            onClick={handleClick}
          >
            <Flex justify="center" align="center" gap={'10px'}>
              <Image src={icon} ml={'15px'} />
              <Text fontFamily="Rowdies" textColor="#FFF6E4" fontSize={'14px'}>
                DOCUMENTS
              </Text>
            </Flex>
          </Button>
          <Image src={line} position={'relative'} />
          <Button
            w="100%"
            h="40px"
            bg="unset"
            variant="link"
          >
            <Flex justify="center" align="center">
              <Image src={icon} mr={'10px'} />
              <Text fontFamily="Rowdies" textColor="#FFF6E4" fontSize={'14px'} mr={'5px'}>
                ABOUT US
              </Text>
            </Flex>
          </Button>
          <Image src={line} position={'relative'} />
        </Box>
        <Box w="80%" textAlign="center" mt="20px">
          <Text fontFamily="Rowdies" fontSize="18px" color="#FFF6E4" as='b' position={'relative'} zIndex={3}>
            JOIN OUR COMMUNITY
          </Text>
          <Flex mt="10px" justifyContent={'space-between'} w={'70%'} ml={'15%'}>
            <Button bg={'unset'} _hover={{bg: 'none'}}><FontAwesomeIcon icon={faSquareXTwitter} fontSize={'2em'} onClick={handleClickX} color='white' className='icon'/></Button>
            <Button bg={'unset'} _hover={{bg: 'none'}}><FontAwesomeIcon icon={faDiscord} fontSize={'2em'} onClick={handleClickDiscord} color='white' className='icon'/></Button>
            <Button bg={'unset'} _hover={{bg: 'none'}}><FontAwesomeIcon icon={faGithub} fontSize={'2em'} onClick={handleClickGithub} color='white' className='icon'/></Button>
            <Button bg={'unset'} _hover={{bg: 'none'}}><FontAwesomeIcon icon={faMediumM} fontSize={'2em'} onClick={handleClickMedium} color='white' className='icon'/></Button>
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
}

export default Footer;
